<template>
  <div class="layout-subcontainer">
    <h1 class="tit-h1">사용자소속 항목관리</h1>
    <div class="box-wrap">
      <div class="box-ct d-flex">
        <div class="">
          <div class="form-inp sm">
            <v-select
              :items="dropItems"
              item-text="CD_NM"
              item-value="CD"
              outlined
              hide-details
              label="회사선택"
              placeholder="선택하세요"
              v-model="ASP_NEWCUST_KEY"
                :readonly="RDOnly"
            >
              <template v-slot:label>
                회사구분
                <v-icon color="#EF9191">mdi-circle-small</v-icon>
              </template>
            </v-select>
          </div>
        </div>
        <div class="ml-auto align-self-center">
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnSearch')" outlined class="btn-etc2" @click="searchAttrInfo" id="btnSearch">조회</v-btn>
        </div>
      </div>
    </div>
    <!-- 소속 -->
    <div class="box-wrap">
      <div class="d-flex">
        <div class="col-3">
          <h2 class="tit-h2 d-flex align-center">
            소속A
            <div class="ml-auto mr-0">
              <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnNew1')" outlined class="btn-default" @click="attrAddBtn('A')" id="btnNew01">신규</v-btn>
              <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnDetail1')" outlined class="btn-default ml-2" @click="sendDetailInfo('A')" id="btnDetail01">상세</v-btn>
              <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnDelete1')" outlined class="btn-etc ml-2" @click="delAttrBtn('A')" id="btnDelete01">삭제</v-btn>
            </div>
          </h2>
          <div class="mt-2">
            <v-data-table
              dense
              height="516px"
              :headers="gridDataHeaders"
              :items="gridDataText.gridDataTextA"
              :items-per-page="30"
              item-key="index"
              :page.sync="page1"
              single-select
              hide-default-footer
              class="grid-default case03"
              fixed-header
              @page-count="pageCount1 = $event"
              v-model="selGroupA"
              v-on:click:row="getListSelA"
              @dblclick:row="sendDetailInfo('A')"
            >
            </v-data-table>
            <div class="grid-paging text-center pt-2">
              <v-pagination
                v-model="page1"
                :length="pageCount1"
                :total-visible="totalVisible"
                next-icon="svg-paging-next"
                prev-icon="svg-paging-prev"
              ></v-pagination>
            </div>
          </div>
        </div>
        <div class="col-3">
          <h2 class="tit-h2 d-flex align-center">
            소속B
            <div class="ml-auto mr-0">
              <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnNew2')" outlined class="btn-default" @click="attrAddBtn('B')" id="btnNew02">신규</v-btn>
              <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnDetail2')" outlined class="btn-default ml-2" @click="sendDetailInfo('B')" id="btnDetail02">상세</v-btn>
              <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnDelete2')" outlined class="btn-etc ml-2" @click="delAttrBtn('B')" id="btnDelete02">삭제</v-btn>
            </div>
          </h2>
          <div class="mt-2">
            <v-data-table
              dense
              height="516px"
              :headers="gridDataHeaders"
              :items="gridDataText.gridDataTextB"
              :items-per-page="30"
              item-key="index"
              :page.sync="page2"
              single-select
              hide-default-footer
              class="grid-default case03"
              fixed-header
              @page-count="pageCount2 = $event"
              v-model="selGroupB"
              v-on:click:row="getListSelB"
              @dblclick:row="sendDetailInfo('B')"
            >
            </v-data-table>
            <div class="grid-paging text-center pt-2">
              <v-pagination
                v-model="page2"
                :length="pageCount2"
                :total-visible="totalVisible"
                next-icon="svg-paging-next"
                prev-icon="svg-paging-prev"
              ></v-pagination>
            </div>
          </div>
        </div>
        <div class="col-3">
          <h2 class="tit-h2 d-flex align-center">
            소속C
            <div class="ml-auto mr-0">
              <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnNew3')" outlined class="btn-default" @click="attrAddBtn('C' )" id="btnNew03">신규</v-btn>
              <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnDetail3')" outlined class="btn-default ml-2" @click="sendDetailInfo('C' )" id="btnDetail03">상세</v-btn>
              <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnDelete3')" outlined class="btn-etc ml-2" @click="delAttrBtn('C' )" id="btnDelete03">삭제</v-btn>
            </div>
          </h2>
          <div class="mt-2">
            <v-data-table
              dense
              height="516px"
              :headers="gridDataHeaders"
              :items="gridDataText.gridDataTextC"
              :items-per-page="30"
              item-key="index"
              :page.sync="page3"
              single-select
              hide-default-footer
              class="grid-default case03"
              fixed-header
              @page-count="pageCount3 = $event"
              v-model="selGroupC"
              v-on:click:row="getListSelC"
              @dblclick:row="sendDetailInfo('C')"
            >
            </v-data-table>
            <div class="grid-paging text-center pt-2">
              <v-pagination
                v-model="page3"
                :length="pageCount3"
                :total-visible="totalVisible"
                next-icon="svg-paging-next"
                prev-icon="svg-paging-prev"
              ></v-pagination>
            </div>
          </div>
        </div>
        <div class="col-3">
          <h2 class="tit-h2 d-flex align-center">
            소속D
            <div class="ml-auto mr-0">
              <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnNew4')" outlined class="btn-default" @click="attrAddBtn('D')" id="btnNew04">신규</v-btn>
              <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnDetail4')" outlined class="btn-default ml-2" @click="sendDetailInfo('D')" id="btnDetail04">상세</v-btn>
              <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnDelete4')" outlined class="btn-etc ml-2" @click="delAttrBtn('D')" id="btnDelete04">삭제</v-btn>
            </div>
          </h2>
          <div class="mt-2">
            <v-data-table
              dense
              height="516px"
              :headers="gridDataHeaders"
              :items="gridDataText.gridDataTextD"
              :items-per-page="30"
              item-key="index"
              :page.sync="page4"
              single-select
              hide-default-footer
              class="grid-default case03"
              fixed-header
              @page-count="pageCount4 = $event"
              v-model="selGroupD"
              v-on:click:row="getListSelD"
              @dblclick:row="sendDetailInfo('D')"
            >
            </v-data-table>
            <div class="grid-paging text-center pt-2">
              <v-pagination
                v-model="page4"
                :length="pageCount4"
                :total-visible="totalVisible"
                next-icon="svg-paging-next"
                prev-icon="svg-paging-prev"
              ></v-pagination>
            </div>
          </div>
        </div>
      </div>
      <v-dialog v-if="dialogM810401P01" max-width="655" persistent v-model="dialogM810401P01"
      content-class="square-modal min-auto">
        <dialog-M810401P01
            headerTitle="사용자정보 신규등록"
            :param="M810401P01Param"
            p04
            @hide="hideDialog('M810401P01')"
            @submit="submitDialog('M810401P01')"
        >
        </dialog-M810401P01>
      </v-dialog>
      <v-dialog v-if="dialogM810401P02" max-width="655" persistent v-model="dialogM810401P02"
      content-class="square-modal min-auto">
        <dialog-M810401P01
            p05
            :param="M810401P02Param"
            headerTitle="사용자 상세정보"
            @hide="hideDialog('M810401P02')"
            @submit="submitDialog('M810401P02')"
        >
        </dialog-M810401P01>
      </v-dialog>
      <v-dialog v-if="dialogM810401P03" max-width="655" persistent v-model="dialogM810401P03"
      content-class="square-modal min-auto">
        <dialog-M810401P01
            p06
            :param="M810401P03Param"
            headerTitle="사용자정보 소속등록"
            @hide="hideDialog('M810401P03')"
            @submit="submitDialog('M810401P03')"
        >
        </dialog-M810401P01>
      </v-dialog>
    </div>
    <!--// 소속 -->
  </div>
</template>

<script>
import DialogM810401P01 from "./M810401P01.vue";
import api from "@/store/apiUtil";
import {mixin} from "../../mixin/mixin";

export default {
  name: "MENU_M810403", //name은 'MENU_' + 파일명 조합
  mixins:[mixin],
  components: {
    DialogM810401P01,
  },
  data() {
    return {
      dropItems: [{ title: "선택", value: ""}],
      dialogM810401P01: false,
      dialogM810401P02: false,
      dialogM810401P03: false,
      gridDataHeaders: [
        {
          text: "코드",
          value: "ATTR_DIV_CD",
          align: "center",
          width: "60px",
          sortable: true,
        },
        {
          text: "소속명",
          value: "ATTR_DIV_NM",
          align: "left",
          sortable: true,
          width: '210px'
        },
        {
          text: "사용여부",
          value: "USE_YN",
          width: "80px",
          align: "center",
          sortable: true,
        },
        {
          text: "순서",
          value: "SORT_ORD",
          width: "60px",
          align: "center",
          sortable: true,
        },
      ],
      gridDataText: {
        gridDataTextA : [],
        gridDataTextB: [],
        gridDataTextC: [],
        gridDataTextD: []
      }
      ,

      //페이징
      page1: 1,
      pageCount1: 0,
      page2: 1,
      pageCount2: 0,
      page3: 1,
      pageCount3: 0,
      page4: 1,
      pageCount4: 0,
      itemsPerPage: 5,
      totalVisible: 10,

      ASP_NEWCUST_KEY:'',

      //등록
      groupFormA:{},
      groupFormB:{},
      groupFormC:{},
      groupFormD:{},

      //상세, 삭제
      selGroupA:[],
      selGroupB:[],
      selGroupC:[],
      selGroupD:[],
      
      RDOnly:false,

    };
  },
  mounted(){
    this.dropItems = this.$store.getters['userStore/GE_USER_COMPANY'];

    let chk = this.mixin_check_AspCustKey_number();
    if(chk == 'Y'){
      this.ASP_NEWCUST_KEY = this.$store.getters["userStore/GE_USER_ROLE"].company[0].CD;
      this.RDOnly = true;
    } else {
      this.RDOnly = false;
    }
  },

  methods: {
    //Table row 클릭이벤트
    getListSelA(item, row){
      if(item != null){
        this.activeRowA(row,true);
      }
    },
    getListSelB(item, row){
      if(item != null){
        this.activeRowB(row,true);
      }
    },
    getListSelC(item, row){
      if(item != null){
        this.activeRowC(row,true);
      }
    },
    getListSelD(item, row){
      if(item != null){
        this.activeRowD(row,true);
      }
    },
    activeRowA(row,value){
      this.selectedRow = row;
      row.select(Boolean(value));
      row.isSelected = Boolean(value);
    },
    activeRowB(row,value){
      this.selectedRow = row;
      row.select(Boolean(value));
      row.isSelected = Boolean(value);
    },
    activeRowC(row,value){
      this.selectedRow = row;
      row.select(Boolean(value));
      row.isSelected = Boolean(value);
    },
    activeRowD(row,value){
      this.selectedRow = row;
      row.select(Boolean(value));
      row.isSelected = Boolean(value);
    },
    showDialog(type) {
      this[`dialog${type}`] = true;
    },
    hideDialog(type) {
      this[`dialog${type}`] = false;
    },
    //완료
    submitDialog(type) {
      console.log("완료");
      this.gridDataText.gridDataTextA = [];
      this.gridDataText.gridDataTextB = [];
      this.gridDataText.gridDataTextC = [];
      this.gridDataText.gridDataTextD = [];
      this.selGroupA = [];
      this.selGroupB = [];
      this.selGroupC = [];
      this.selGroupD = [];

      this.searchAttrInfo();
      this[`dialog${type}`] = false;
    },
    //리셋
    resetDialog(type) {
      console.log("reset");
      this.searchAttrInfo();
      this[`dialog${type}`] = false;
    },
    //해제
    unlockDialog(type) {
      console.log("unlock");
      this.searchAttrInfo();
      this[`dialog${type}`] = false;
    },


    searchAttrInfo() {
      let companyKey = this.ASP_NEWCUST_KEY;

      if(!companyKey){
        let msg = '회사구분란에서 회사를 선택후 이용해주십시오';
        this.common_alert(msg, "error");

        return
      }

      //초기화
      this.selGroupA=[];
      this.selGroupB=[];
      this.selGroupC=[];
      this.selGroupD=[];
      this.selGroupCd = {};

      if (companyKey) {
        let requestData = {
          headers: {
            SERVICE: this.initHeaders.SERVICE,
            METHOD: this.initHeaders.METHOD,
            TYPE: this.initHeaders.TYPE,
          },
          sendData: {}
        };

        // header 세팅
        requestData.headers["URL"] = "/api/setting/agent/psitn-iem-manage/inqire";
        requestData.headers["METHOD"] = "inqire";
        requestData.headers["GRID_ID"] = "";

        // sendData 세팅
        requestData.sendData["ASP_NEWCUST_KEY"] = this.ASP_NEWCUST_KEY;

        for(let i=0; i < 4; i++){
          switch (i){
            case 0:
              this.ATTR_CD = "A";
              requestData.headers["GRID_ID"] = "divGrid01";
              requestData.sendData["ATTR_CD"] = this.ATTR_CD;
              this.result(requestData);
              continue;
            case 1 :
              this.ATTR_CD = "B";
              requestData.headers["GRID_ID"] = "divGrid02";
              requestData.sendData["ATTR_CD"] = this.ATTR_CD;
              this.result(requestData);
              continue;
            case 2 :
              this.ATTR_CD = "C";
              requestData.headers["GRID_ID"] = "divGrid03";
              requestData.sendData["ATTR_CD"] = this.ATTR_CD;
              this.result(requestData);
              continue;
            case 3 :
              this.ATTR_CD = "D";
              requestData.headers["GRID_ID"] = "divGrid04";
              requestData.sendData["ATTR_CD"] = this.ATTR_CD;
              this.result(requestData);
              break;
          }
        }
      }
    },
    searchAttrInfoCallBackA(response){
        let header = response.HEADER;
        let data = response.DATA;

          this.gridDataText.gridDataTextA = data;

        let idx = 1;
        for(let i in this.gridDataText.gridDataTextA){
          this.gridDataText.gridDataTextA[i]["index"] = idx++;
        }
      },
    searchAttrInfoCallBackB(response){
      let header = response.HEADER;
      let data = response.DATA;

      this.gridDataText.gridDataTextB = data;

      let idx = 1;
      for(let i in this.gridDataText.gridDataTextB){
        this.gridDataText.gridDataTextB[i]["index"] = idx++;
      }
    },
    searchAttrInfoCallBackC(response){
      let header = response.HEADER;
      let data = response.DATA;

      this.gridDataText.gridDataTextC = data;

      let idx = 1;
      for(let i in this.gridDataText.gridDataTextC){
        this.gridDataText.gridDataTextC[i]["index"] = idx++;
      }
    },
    searchAttrInfoCallBackD(response){
      let header = response.HEADER;
      let data = response.DATA;

      this.gridDataText.gridDataTextD = data;

      let idx = 1;
      for(let i in this.gridDataText.gridDataTextD){
        this.gridDataText.gridDataTextD[i]["index"] = idx++;
      }
    },
    showAlert(){
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: true,
        msg: '회사구분란에서 회사를 선택후 이용해주십시오',
        iconClass: 'svg-error-lg',
        type: 'default',
      })
    },

    //소속등록 버튼
    attrAddBtn(divNum){
      let companyKey = this.ASP_NEWCUST_KEY;

      if(divNum == 'A'){
        if (companyKey) {
          this.groupFormA = {
            ASP_NEWCUST_KEY : companyKey,
            ATTR_CD : divNum,
          }
          this.M810401P01Param = {
            headerTitle: "사용자소속항목 등록",
            data: this.groupFormA,
            type: "ATTR_I",
          }
          this.showDialog('M810401P01');
          }else if(!companyKey){
          this.showAlert();
        }else if(Object.keys(this.gridDataText.gridDataTextA[0]).length == 0){
          this.common_alert("선택된 항목이 존재하지 않습니다.", "error");
        }

      }else if(divNum == 'B'){
        if (companyKey) {
          this.groupFormB = {
            ASP_NEWCUST_KEY: companyKey,
            ATTR_CD: divNum,
          }
          this.M810401P01Param = {
            headerTitle: "사용자소속항목 등록",
            data: this.groupFormB,
            type: "ATTR_I",
          }
          this.showDialog('M810401P01');
        }else if(!companyKey){
          this.showAlert();
        }else if(Object.keys(this.gridDataText.gridDataTextB[0]).length == 0){
          this.common_alert("선택된 항목이 존재하지 않습니다.", "error");
        }

      }else if(divNum == 'C'){
        if (companyKey) {
          this.groupFormC = {
            ASP_NEWCUST_KEY: companyKey,
            ATTR_CD: divNum,
          }
          this.M810401P01Param = {
            headerTitle: "사용자소속항목 등록",
            data: this.groupFormC,
            type: "ATTR_I",
          }
          this.showDialog('M810401P01');
        }else if(!companyKey){
          this.showAlert();
        }else if(Object.keys(this.gridDataText.gridDataTextC[0]).length == 0){
          this.common_alert("선택된 항목이 존재하지 않습니다.", "error");
        }

      }else if(divNum == 'D'){
        if (companyKey) {
          this.groupFormD = {
            ASP_NEWCUST_KEY: companyKey,
            ATTR_CD: divNum,
          }
          this.M810401P01Param = {
            headerTitle: "사용자소속항목 등록",
            data: this.groupFormD,
            type: "ATTR_I",
          }
          this.showDialog('M810401P01');
        }else if(!companyKey){
          this.showAlert();
        } else if (Object.keys(this.gridDataText.gridDataTextD[0]).length == 0) {
          this.common_alert("선택된 항목이 존재하지 않습니다.", "error");
        }
      }

    },

    //소속수정버튼
    sendDetailInfo(divNum){
      if(divNum == 'A'){
        if( Object.keys(this.selGroupA).length === 0 ) {
          this.$store.commit("alertStore/openAlertDialog", {
            alertDialogToggle: true,
            msg: "선택된 항목이 존재하지 않습니다.",
            iconClass: "svg-error-lg",
            type: "default",
          });
          return;
        }
        this.M810401P02Param = {
          headerTitle: "사용자소속항목 상세정보",
          data: this.selGroupA[0],
          type: "ATTR_U",
        }
      }else if(divNum == 'B'){
        if( Object.keys(this.selGroupB).length === 0 ) {
          this.$store.commit("alertStore/openAlertDialog", {
            alertDialogToggle: true,
            msg: "선택된 항목이 존재하지 않습니다.",
            iconClass: "svg-error-lg",
            type: "default",
          });
          return;
        }
        this.M810401P02Param = {
          headerTitle: "사용자 상세정보",
          data: this.selGroupB[0],
          type: "ATTR_U",
        }
      }else if(divNum == 'C'){
        if( Object.keys(this.selGroupC).length === 0 ) {
          this.$store.commit("alertStore/openAlertDialog", {
            alertDialogToggle: true,
            msg: "선택된 항목이 존재하지 않습니다.",
            iconClass: "svg-error-lg",
            type: "default",
          });
          return;
        }
        this.M810401P02Param = {
          headerTitle: "사용자 상세정보",
          data: this.selGroupC[0],
          type: "ATTR_U",
        }
      }else if(divNum == 'D'){
        if( Object.keys(this.selGroupD).length === 0 ) {
          this.$store.commit("alertStore/openAlertDialog", {
            alertDialogToggle: true,
            msg: "선택된 항목이 존재하지 않습니다.",
            iconClass: "svg-error-lg",
            type: "default",
          });
          return;
        }
        this.M810401P02Param = {
          headerTitle: "사용자 상세정보",
          data: this.selGroupD[0],
          type: "ATTR_U",
        }
      }
      this.showDialog('M810401P02');

    },

    //소속삭제버튼
    delAttrBtn(divNum){
      if(divNum == "A"){
        if (Object.keys(this.selGroupA).length != 0) {
          console.log(Object.keys(this.selGroupA).length == 0 ? "y" : "N");
          let message = "해당 소속정보를 삭제하시겠습니까?"
          let iconType = "error"
          this.common_confirm(message, this.deleteAttr, divNum, null, null, iconType);
        }else if(Object.keys(this.selGroupA).length == 0){
          this.common_alert("선택된 항목이 존재하지 않습니다.", "error")
        }
      }
      if(divNum == "B"){
        if (Object.keys(this.selGroupB).length != 0) {
          let message = "해당 소속정보를 삭제하시겠습니까?"
          let iconType = "error"
          this.common_confirm(message, this.deleteAttr, divNum, null, null, iconType);
        }else if(Object.keys(this.selGroupB).length == 0){
          this.common_alert("선택된 항목이 존재하지 않습니다.", "error")
        }
      }
      if(divNum == "C"){
        if (Object.keys(this.selGroupC).length != 0) {
          console.log(Object.keys(this.selGroupC).length == 0 ? "y" : "N");
          let message = "해당 소속정보를 삭제하시겠습니까?"
          let iconType = "error"
          this.common_confirm(message, this.deleteAttr, divNum, null, null, iconType);
        }else if(Object.keys(this.selGroupC).length == 0){
          this.common_alert("선택된 항목이 존재하지 않습니다.", "error")
        }
      }
      if(divNum == "D"){
        if (Object.keys(this.selGroupD).length != 0) {
          let message = "해당 소속정보를 삭제하시겠습니까?"
          let iconType = "error"
          this.common_confirm(message, this.deleteAttr, divNum, null, null, iconType);
        }else if(Object.keys(this.selGroupD).length == 0){
          this.common_alert("선택된 항목이 존재하지 않습니다.", "error")
        }
      }
    },

    //소속삭제
    async deleteAttr(divNum){
      let requestData = {
        headers: {},
        sendData:{}
      };
      // header 세팅
      requestData.headers["URL"] = "/api/setting/agent/psitn-iem-manage/delete";
      requestData.headers["SERVICE"] = "setting.agent.psitn-iem-manage";
      requestData.headers["METHOD"] = "delete";
      requestData.headers["TYPE"] = "BIZ_SERVICE";
      requestData.headers["ASYNC"] = false;

      // sendData 세팅
      if(divNum == "A"){
      requestData.sendData["ASP_NEWCUST_KEY"] = this.selGroupA[0].ASP_NEWCUST_KEY;
      requestData.sendData["ATTR_CD"] = divNum;
      requestData.sendData["ATTR_DIV_CD"] = this.selGroupA[0].ATTR_DIV_CD;

      let deleteAttrResponse = await this.common_postCall(requestData);
      this.deleteAttrCallback(deleteAttrResponse);
      }else if(divNum == "B"){
        requestData.sendData["ASP_NEWCUST_KEY"] = this.selGroupB[0].ASP_NEWCUST_KEY;
        requestData.sendData["ATTR_CD"] = divNum;
        requestData.sendData["ATTR_DIV_CD"] = this.selGroupB[0].ATTR_DIV_CD;

        let deleteAttrResponse = await this.common_postCall(requestData);
        this.deleteAttrCallback(deleteAttrResponse);
      }else if(divNum == "C"){
        requestData.sendData["ASP_NEWCUST_KEY"] = this.selGroupC[0].ASP_NEWCUST_KEY;
        requestData.sendData["ATTR_CD"] = divNum;
        requestData.sendData["ATTR_DIV_CD"] = this.selGroupC[0].ATTR_DIV_CD;

        let deleteAttrResponse = await this.common_postCall(requestData);
        this.deleteAttrCallback(deleteAttrResponse);
      }else if(divNum == "D"){
        requestData.sendData["ASP_NEWCUST_KEY"] = this.selGroupD[0].ASP_NEWCUST_KEY;
        requestData.sendData["ATTR_CD"] = divNum;
        requestData.sendData["ATTR_DIV_CD"] = this.selGroupD[0].ATTR_DIV_CD;

        let deleteAttrResponse = await this.common_postCall(requestData);
        this.deleteAttrCallback(deleteAttrResponse);
      }

    },

    //소속삭제 콜백
    deleteAttrCallback(response){
      if(response.HEADER.ERROR_FLAG == false){
        let msg = "정상처리 되었습니다.";
        this.selGroupA = [];
        this.selGroupB = [];
        this.selGroupC = [];
        this.selGroupD = [];
        this.gridDataText.gridDataTextA = [];
        this.gridDataText.gridDataTextB = [];
        this.gridDataText.gridDataTextC = [];
        this.gridDataText.gridDataTextD = [];
        //alert창 띄우기
        this.common_alert(msg, "done");
        //재조회
        this.searchAttrInfo();


      }
    },

    async result(requestData){
      //api 호출 부분
      await api.post(requestData.headers.URL,   //api url입력
          //await axios.post('https://local.hkpalette.com:8443/'+this.URLData,{
          requestData.sendData
          , //api에서 사용될 data 입력
          {head: requestData.headers
          }) //api에 로그를 남기기위한 값입력 (필수) (SERVICE, METHOD, TYPE)
          .then((response) => {
            // string으로 넘어올 경우 에러임.
            if(typeof(response.data) === "string"){
              let responseData = JSON.parse(response.data.replaceAll("\n", ""));
              this.$store.commit("alertStore/openAlertDialog", {
                alertDialogToggle: true,
                msg: responseData.HEADER.ERROR_MSG,
                iconClass: "svg-error-lg",
                type: "default",
              });
              return;
            }

            if(response.data.HEADER.ERROR_FLAG){
              let msg = response.data.HEADER.ERROR_MSG
              this.common_alert(msg , "error");
              return;
            }

            if(response.data.HEADER.METHOD == "inqire"){
              if(response.data.HEADER.GRID_ID == "divGrid01"){
                this.searchAttrInfoCallBackA(response.data);
              }else if(response.data.HEADER.GRID_ID == "divGrid02"){
                this.searchAttrInfoCallBackB(response.data);
              }else if(response.data.HEADER.GRID_ID == "divGrid03"){
                this.searchAttrInfoCallBackC(response.data);
              }else if(response.data.HEADER.GRID_ID == "divGrid04"){
                this.searchAttrInfoCallBackD(response.data);
              }
            }

          }) //api 호출 성공 이후 수행
          .catch((err) => {
            alert(err);
          }) //api호출 에러 작업 수행

      //결과값 보여주기
    },
  },
  computed: {
    initHeaders(){
      return {
        SERVICE : 'setting.agent.psitn-iem-manage',
        METHOD : "",
        TYPE : 'BIZ_SERVICE',
      };
    },
  },
};
</script>

<style></style>
